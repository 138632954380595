import * as React from "react";
import { useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";
import parse, { attributesToProps } from 'html-react-parser'
import dayjs from "dayjs"
import 'dayjs/locale/es'
import { motion } from 'framer-motion'
import { SupabaseContext } from "../contexts/SupabaseContext";
import { supabase } from "../supabaseClient";
import { getUserProfile } from '../api/supabase'

import { VideoPlayer, WikiHowDetailCards, WikiHowStepsCards } from "../components/common/index";
import { CommentsBlock } from '../blocks/Index'
import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import { getPostViews } from "../utils/getPostViews";

import * as postStyles from '../styles/post.module.css'
import { CryptoIcon, FacebookIcon, FeaturedIcon, PoliticsIcon, ScienceIcon, TechIcon, TwitterIcon } from "../assets/Index";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */

const Post = ({ data, location }) => {
    dayjs.locale('es');
    const relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    const post = data.ghostPost;
    const { session, user } = useContext(SupabaseContext);
    const userId = session ? session.user.id : '';
    const [userData, setUserData] = useState(null);
    const readingTime = readingTimeHelper(post);
    const isWikiHowPost = (post) => post && post.tags && post.tags.length > 0 && post.tags[0].name === 'wikihow';

    const [views, setViews] = useState(0);

    useEffect(() => {
        async function fetchViews() {
            const postSlug = `/${post.slug}/`;
            const views = await getPostViews(postSlug);
            setViews(views);
        }

        fetchViews();
    }, [post])

    useEffect(() => {
        async function getUserData() {
            const data = await getUserProfile(userId);
            if (data) {
                setUserData(data)
            }
        }
        if (user) {
            getUserData();
        }
    }, [session])

    const handleLoginWithFacebook = async () => {
        try {
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'facebook',
                options: {
                    scopes: 'email, public_profile',
                    queryParams: {
                        picture: {
                            data: {
                                weight: 9999,
                                heigh: 9999,
                            }
                        }
                    },
                    redirectTo: `https://updtodo.com/${post.slug}`
                },
            })

        } catch (error) {
            alert(error.error_description || error.message)
        } finally {
        }
    };
    const handleLoginWithTwitter = async () => {
        try {
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'twitter',
            })

        } catch (error) {
            alert(error.error_description || error.message)
        } finally {
        }
    };

    const regularpost = {
        replace: (domNode) => {
            if (
                domNode.attribs &&
                domNode.attribs.class &&
                domNode.attribs.class.includes('kg-video-container')
            ) {
                const newChildren = [];

                domNode.children.forEach((child) => {
                    if (child.name === 'video') {
                        const videoProps = attributesToProps(child.attribs);
                        const parsedVideo = React.createElement(VideoPlayer, {
                            key: 'video',
                            videoUrl: videoProps.src,
                            posterUrl: post.feature_image,
                            bgColor: post.feature_image_alt,
                            size: 'large',
                            context: 'post',
                        });
                        newChildren.push(parsedVideo);
                    }
                });

                const props = attributesToProps(domNode.attribs);
                return React.createElement('div', props, newChildren);
            }
        },
    };
    const regularPostParse = parse(post.html, regularpost);
    const extractJsonData = (htmlString) => {
        const jsonPattern = /{[\s\S]*}/;
        const match = htmlString.match(jsonPattern);

        if (match) {
            try {
                return JSON.parse(match[0]);
            } catch (error) {
                console.error('Error parsing JSON data:', error);
            }
        }

        return null;
    };
    const jsonData = () => {
        if (isWikiHowPost(post)) {
            return extractJsonData(post.html);
        }
    };
    const wikiHowPostData = jsonData();
    const setTags = (tags) => {
        const result = [];
        if (post.featured === true) {
            result.push("destacado");
        }
        if (tags && tags.length > 1) {
            result.push(tags[1].name);
        }
        return result;
    };

    // Handle each WikiHowStepsCard task 'open' event instance separately
    const [taskState, setTaskState] = useState(
        isWikiHowPost(post)
            ? wikiHowPostData.postSteps.map((step) =>
                step.tasks.map(() => ({ selectedTaskIndex: null, selectedSubTaskIndex: null }))
            )
            : null
    );

    const handleTaskClick = (stepIndex, taskIndex) => {
        setTaskState((prevState) =>
            prevState.map((step, index) => {
                if (index === stepIndex) {
                    return {
                        ...step,
                        selectedTaskIndex:
                            step.selectedTaskIndex === taskIndex ? null : taskIndex,
                        selectedSubTaskIndex: null,
                    };
                } else {
                    return {
                        ...step,
                        selectedTaskIndex: null,
                        selectedSubTaskIndex: null,
                    };
                }
            })
        );
    };
    // Handle the WikiHowStepsCard 'next' event per instance
    const cardRefs = isWikiHowPost(post) ? wikiHowPostData.postSteps.map(() => useRef(null)) : null;

    const handleSubTaskClick = (stepIndex, subTaskIndex) => {
        setTaskState((prevState) =>
            prevState.map((step, index) => {
                if (index === stepIndex) {
                    return {
                        ...step,
                        selectedSubTaskIndex:
                            step.selectedSubTaskIndex === subTaskIndex
                                ? null
                                : subTaskIndex,
                    };
                } else {
                    return step;
                }
            })
        );
    };
    const postTags = setTags(post.tags, post.featured);
    const drawLine = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: { pathLength: 1, opacity: 1, transition: { type: 'spring', duration: 3 } }
    }

    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <article className={postStyles.post}>
                    {
                        post.tags[0].name === 'wikihow' && post.tags.some((s) => s.name.includes('#guia')) ?
                            (
                                <>
                                    <header className={postStyles.header} data-headertype={'wikihow'}>
                                        <div className={postStyles.floatingcontent}>
                                            <div className={postStyles.floatingtags}>
                                                {
                                                    postTags.map((m) => (
                                                        m === 'destacado'
                                                            ? (
                                                                <div key={m} className={postStyles.featuredtagwrapper}>
                                                                    <div className={postStyles.featuredtag}>
                                                                        <FeaturedIcon />
                                                                        <p className="topic-tag">
                                                                            {m}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                <Link to={`/tag/${m}`} key={m} className={postStyles.normaltag}>
                                                                    {m === 'tech'
                                                                        ?
                                                                        <TechIcon />
                                                                        : m === 'crypto'
                                                                            ? <CryptoIcon />
                                                                            : m === 'politica'
                                                                                ? <PoliticsIcon />
                                                                                : m === 'ciencias'
                                                                                    ? <ScienceIcon />
                                                                                    : ''
                                                                    }
                                                                    <p>
                                                                        {m === 'politica' ? 'política' : m}
                                                                    </p>
                                                                </Link>
                                                            )
                                                    ))
                                                }
                                                <div className={postStyles.views}>
                                                    <i className="material-icons-round">visibility</i>
                                                    <p>
                                                        {
                                                            views
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={postStyles.postdetails}>
                                                <h1 className="hero">
                                                    {
                                                        post.title
                                                    }
                                                </h1>
                                                <div className={postStyles.whdetailscardswrapper}>
                                                    <WikiHowDetailCards data={wikiHowPostData.postDetails} tag={post.tags[1].name} />
                                                </div>
                                                <div className={postStyles.postauthor}>
                                                    <Link to={`/author/${post.primary_author.slug}`} className={postStyles.authorprofilepic}>
                                                        <img src={post.primary_author.profile_image} alt={post.primary_author.name} />
                                                    </Link>
                                                    <figure className={postStyles.data}>
                                                        <Link to={`/author/${post.primary_author.slug}`} className={postStyles.authorname}>
                                                            {post.primary_author.name}
                                                        </Link>
                                                        <p>
                                                            {`${dayjs(post.created_at).fromNow()} • ${readingTime}`}
                                                        </p>
                                                    </figure>
                                                    <div className={postStyles.pauthordivider}>
                                                        {/* Divider */}
                                                    </div>
                                                    <div className={postStyles.todowrapper}>
                                                        <div className={postStyles.time}>
                                                            <i className="material-icons-round">timer</i>
                                                            <p className={postStyles.timevalue}>{wikiHowPostData.postDetails[0].timetodo}</p>
                                                            <p className={postStyles.timeunit}>{wikiHowPostData.postDetails[0].timeunit}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={postStyles.featuredimg} style={{ backgroundColor: post.feature_image_alt }}>
                                            <img src={post.feature_image} alt={post.title} />
                                        </div>
                                    </header>
                                    <section className={postStyles.whcontent}>
                                        {
                                            wikiHowPostData.postSteps.map((steps, index) => (
                                                <WikiHowStepsCards
                                                    data={steps}
                                                    key={index}
                                                    index={index}
                                                    tag={post.tags[1].name}
                                                    length={wikiHowPostData.postSteps.length}
                                                    taskState={taskState[index]}
                                                    onTaskClick={(taskIndex) => handleTaskClick(index, taskIndex)}
                                                    onSubTaskClick={(subTaskIndex) => handleSubTaskClick(index, subTaskIndex)}
                                                    cardRefs={cardRefs}
                                                />
                                            ))
                                        }
                                    </section>
                                    {user && userData ? (
                                        <CommentsBlock postId={post.id} postExcerpt={post.excerpt} provider={user.identities[0].provider} userId={userId} userName={userData.full_name} postSlug={post.slug} />
                                    ) : (
                                        <div className={postStyles.loginwrapper}>
                                            <div className={postStyles.logintitle}>
                                                <h3>
                                                    Ingresar por
                                                </h3>
                                            </div>
                                            <div className={postStyles.loginoptions}>
                                                <button className={postStyles.loginbutton} onClick={handleLoginWithFacebook}>
                                                    <div>
                                                        <FacebookIcon />
                                                    </div>
                                                    <p>
                                                        facebook
                                                    </p>
                                                </button>
                                                <button className={postStyles.loginbutton} onClick={handleLoginWithTwitter}>
                                                    <div>
                                                        <TwitterIcon />
                                                    </div>
                                                    <p>
                                                        twitter
                                                    </p>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )
                            :
                            (
                                <>
                                    <header className={postStyles.header}>
                                        <div className={postStyles.floatingcontent}>
                                            <div className={postStyles.floatingtags}>
                                                {
                                                    postTags.map((m) => (
                                                        m === 'destacado'
                                                            ? (
                                                                <div key={m} className={postStyles.featuredtagwrapper}>
                                                                    <div className={postStyles.featuredtag}>
                                                                        <FeaturedIcon />
                                                                        <p className="topic-tag">
                                                                            {m}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                <Link to={`/tag/${m}`} key={m} className={postStyles.normaltag}>
                                                                    {m === 'tech'
                                                                        ?
                                                                        <TechIcon />
                                                                        : m === 'crypto'
                                                                            ? <CryptoIcon />
                                                                            : m === 'politica'
                                                                                ? <PoliticsIcon />
                                                                                : m === 'ciencias'
                                                                                    ? <ScienceIcon />
                                                                                    : ''
                                                                    }
                                                                    <p>
                                                                        {m === 'politica' ? 'política' : m}
                                                                    </p>
                                                                </Link>
                                                            )
                                                    ))
                                                }
                                                <div className={postStyles.views}>
                                                    <i className="material-icons-round">visibility</i>
                                                    <p>
                                                        {
                                                            views
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={postStyles.postdetails}>
                                                <h1 className="hero">
                                                    {
                                                        post.title
                                                    }
                                                </h1>
                                                <div className={postStyles.postdescription}>
                                                    <motion.svg width="17" height="73" viewBox="0 0 17 73" fill="none" xmlns="http://www.w3.org/2000/svg" initial='hidden' animate='visible'>
                                                        <motion.path variants={drawLine} d="M15 2C15 8.9 2 8.9 2 15.8C2 22.7 15 22.7 15 29.6C15 36.5 2 36.5 2 43.4C2 50.3 15 50.3 15 57.2C15 64.1 2 64.1 2 71" stroke="#F39C12" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
                                                    </motion.svg>
                                                    <p className="hero-description">
                                                        {post.excerpt}
                                                    </p>
                                                </div>
                                                <div className={postStyles.postauthor}>
                                                    <Link to={`/author/${post.primary_author.slug}`} className={postStyles.authorprofilepic}>
                                                        <img src={post.primary_author.profile_image} alt={post.primary_author.name} />
                                                    </Link>
                                                    <figure className={postStyles.data}>
                                                        <Link to={`/author/${post.primary_author.slug}`} className={postStyles.authorname}>
                                                            {post.primary_author.name}
                                                        </Link>
                                                        <p>
                                                            {`${dayjs(post.created_at).fromNow()} • ${readingTime}`}
                                                        </p>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={postStyles.featuredimg} style={{ backgroundColor: post.feature_image_alt }}>
                                            <img src={post.feature_image} alt={post.title} />
                                        </div>
                                    </header>
                                    <section className={postStyles.content}>
                                        {regularPostParse}
                                    </section>
                                    {user && userData ? (
                                        <CommentsBlock postId={post.id} postExcerpt={post.excerpt} provider={user.identities[0].provider} userId={userId} userName={userData.full_name} postSlug={post.slug} />
                                    ) : (
                                        <div className={postStyles.loginwrapper}>
                                            <div className={postStyles.logintitle}>
                                                <h3>
                                                    Ingresar por
                                                </h3>
                                            </div>
                                            <div className={postStyles.loginoptions}>
                                                <button className={postStyles.loginbutton} onClick={handleLoginWithFacebook}>
                                                    <div>
                                                        <FacebookIcon />
                                                    </div>
                                                    <p>
                                                        facebook
                                                    </p>
                                                </button>
                                                <button className={postStyles.loginbutton} onClick={handleLoginWithTwitter}>
                                                    <div>
                                                        <TwitterIcon />
                                                    </div>
                                                    <p>
                                                        twitter
                                                    </p>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )
                    }
                </article>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`;
