// extracted by mini-css-extract-plugin
export var authorname = "post-module--authorname--bvJ+w";
export var authorprofilepic = "post-module--authorprofilepic--ZC5t-";
export var content = "post-module--content--sCc+c";
export var data = "post-module--data--DHGZi";
export var featuredimg = "post-module--featuredimg--Q-yts";
export var featuredtag = "post-module--featuredtag--Feker";
export var featuredtagwrapper = "post-module--featuredtagwrapper--hj8Gx";
export var floatingcontent = "post-module--floatingcontent--yHBqN";
export var floatingtags = "post-module--floatingtags--NXVxg";
export var header = "post-module--header--RJKCi";
export var loginbutton = "post-module--loginbutton--VWLSZ";
export var loginoptions = "post-module--loginoptions--2oFu7";
export var logintitle = "post-module--logintitle--AQcEX";
export var loginwrapper = "post-module--loginwrapper--yvL7a";
export var normaltag = "post-module--normaltag--F94VA";
export var pauthordivider = "post-module--pauthordivider--+rNZd";
export var post = "post-module--post--3LtSx";
export var postauthor = "post-module--postauthor--7IIRe";
export var postdescription = "post-module--postdescription--QWMpM";
export var postdetails = "post-module--postdetails--4ObY7";
export var time = "post-module--time--DgSgB";
export var timeunit = "post-module--timeunit--dgsVK";
export var timevalue = "post-module--timevalue--gP1JQ";
export var todowrapper = "post-module--todowrapper--PQe1d";
export var views = "post-module--views--vDDG-";
export var whcontent = "post-module--whcontent--QMk3u";
export var whdetailscardswrapper = "post-module--whdetailscardswrapper--oAdJp";