import React, { useState, useEffect } from 'react';
import { Reactions, Disclaimer, DropDown, Comment } from '../components/common/index';
import { fetchComments, signOutFunction } from '../api/supabase';
import { supabase } from '../supabaseClient';

import * as Styles from '../styles/commentsblock.module.css'

const CommentsBlock = ({ postId, postExcerpt, postSlug, provider, userId, userName }) => {
  const [comments, setComments] = useState([]);
  const [disclaimerState, setDisclaimerState] = useState(false);
  const disclaimer = 'Todos los comentarios son bienvenidos siempre y cuando estos mantengan el respeto y la cordialidad. Todos los comentarios son moderados y pueden ser eliminados a nuestra discreción.'

  useEffect(() => {
    const fetchAndSetComments = async () => {
      const fetchedComments = await fetchComments(postId);
      if (fetchedComments) {
        setComments(fetchedComments);
      }
    };

    fetchAndSetComments();
    const subscription = supabase
      .channel('public:comments')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'comments',
        filter: `post_id=eq.${postId}`
      }, payload => {
        if (payload.eventType === 'INSERT') {
          setComments((current) => [...current, payload.new]);
        } else if (payload.eventType === 'DELETE') {
          setComments((current) => current.filter((comment) => comment.comment_id !== payload.old.comment_id));
        } else if (payload.eventType === 'UPDATE') {
          setComments((current) => {
            const commentIndex = current.findIndex(comment => comment.comment_id === payload.new.comment_id);
            if (commentIndex !== -1) {
              const updatedComments = [
                ...current.slice(0, commentIndex),
                payload.new,
                ...current.slice(commentIndex + 1),
              ];
              return updatedComments;
            }
            return current;
          });
        } else {
        }
      })
      .subscribe();

    return () => {
      if (subscription) {
        supabase.removeChannel(subscription);
      }
    };
  }, [postId]);

  const handleAcknowledge = (state) => {
    setDisclaimerState(state);
  }

  return (
    <div className={Styles.commentsblock}>
      <Reactions postId={postId} provider={provider} userId={userId} postExcerpt={postExcerpt} postSlug={postSlug} />
      {disclaimerState === false && (<Disclaimer title={'Política de Comentarios'} disclaimer={disclaimer} onAcknowledge={(state) => handleAcknowledge(state)} />)}
      <div className={Styles.contentwrapper}>
        <DropDown name={`@${userName}`} menuItems={[{ text: 'Cerrar Sesión', icon: 'logout', action: signOutFunction }]} />
        <div className={Styles.commentswrapper}>
          {comments && comments.length > 0 && (comments.map((commentData, Index) => (
            <Comment
              key={Index}
              postId={postId}
              commentData={commentData}
              userId={userId}
              userName={userName}
              readOnly={true}
            />
          )))}
          <Comment postId={postId} userName={userName} userId={userId} readOnly={false} />
        </div>
      </div>
    </div>
  );
};

export default CommentsBlock;