import axios from "axios";

export async function getPostViews(slug) {
  const response = await axios.get("/.netlify/functions/postViews", { params: { slug } });
  if (response.status === 200) {
    return response.data.views;
  } else {
    console.error("Error fetching post views:", response);
    return 0;
  }
}